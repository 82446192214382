import * as React from "react";

const Home3 = (props) => {
  return (
    <div class="home 3">
      {" "}
      <div class="hero mt-10 md:mt-20 items-center pb-10 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="md:flex justify-between items-start">
            <div class="md:w-1/2">
              <nav class="hidden md:block opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Residential
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home2";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        House Info
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white hover:text-gray-200">
                        Energy
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Residential
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                The type of energy your home uses has an impact on your carbon
                footprint.
              </p>{" "}
              <div class="energy options mt-5 border border-orange-200 bg-orange-100 pt-6 pb-6 px-4 rounded-lg">
                <h2 class="text-base font-medium md:text-lg text-gray-600">
                  Does your house use any of the following?{" "}
                  <span class="text-xs">(Click all that Apply)</span>
                </h2>

                <fieldset class="pt-6">
                  <div class="relative md:flex justify-start items-start">
                    {props.electricityCheck === true ? (
                      <div class="flex items-center h-5">
                        <input
                          type="checkbox"
                          checked
                          onClick={props.electricityChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Electricity
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div class="flex items-center h-5">
                        <input
                          type="checkbox"
                          onClick={props.electricityChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Electricity
                          </label>
                        </div>
                      </div>
                    )}

                    {props.data.uetGreen === "true" ? (
                      <div class="mt-2 sm:mt-0 opacity-40 sm:ml-10 flex items-center h-5">
                        <input
                          type="checkbox"
                          disabled
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-ng"
                            class="font-medium text-gray-700"
                          >
                            Natural Gas
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {props.naturalGasCheck === true ? (
                          <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                            <input
                              id="house-energy-ng"
                              name="house-energy-ng"
                              type="checkbox"
                              checked
                              onClick={props.naturalGasChange}
                              class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                            />
                            <div class="ml-3 text-sm">
                              <label
                                for="house-energy-ng"
                                class="font-medium text-gray-700"
                              >
                                Natural Gas
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                            <input
                              id="house-energy-ng"
                              name="house-energy-ng"
                              type="checkbox"
                              onClick={props.naturalGasChange}
                              class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                            />
                            <div class="ml-3 text-sm">
                              <label
                                for="house-energy-ng"
                                class="font-medium text-gray-700"
                              >
                                Natural Gas
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {props.propaneCheck === true ? (
                      <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                        <input
                          id="house-energy-propane"
                          name="house-energy-propane"
                          type="checkbox"
                          checked
                          onClick={props.propaneChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Propane
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                        <input
                          id="house-energy-propane"
                          name="house-energy-propane"
                          type="checkbox"
                          onClick={props.propaneChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Propane
                          </label>
                        </div>
                      </div>
                    )}

                    {props.heatingOilCheck === true ? (
                      <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                        <input
                          id="house-energy-propane"
                          name="house-energy-propane"
                          type="checkbox"
                          checked
                          onClick={props.heatingOilChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Heating Oil
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div class="mt-2 sm:mt-0 sm:ml-10 flex items-center h-5">
                        <input
                          id="house-energy-propane"
                          name="house-energy-propane"
                          type="checkbox"
                          onClick={props.heatingOilChange}
                          class="focus:ring-orange h-5 w-5 sm:h-4 sm:w-4 text-orange border-gray-300 rounded"
                        />
                        <div class="ml-3 text-sm">
                          <label
                            for="house-energy-electricity"
                            class="font-medium text-gray-700"
                          >
                            Heating Oil
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </fieldset>
              </div>
              {props.electricityCheck ||
              props.naturalGasCheck ||
              props.heatingOilCheck ||
              props.propaneCheck ? (
                <p class="transform pt-6 mt-6 text-sm font-medium text-yellow-100">
                  Your energy usage amounts have been pre-loaded from your
                  previous selections based on our formulas. You can keep these
                  estimates or enter your own usage in the fields below.
                </p>
              ) : (
                <div></div>
              )}
              {props.electricityCheck === true ? (
                <div class="w-full electricity mt-8 border-b pb-8 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-100 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M14 19h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772zm4-.691v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm-3.743 1.294l-1.04-1.94 1.208-.648 1.037 1.933c-.418.181-.822.401-1.205.655zm10.586 1.735l1.942-1.394.799 1.115-2.054 1.473c-.191-.43-.423-.827-.687-1.194zm-3.01-2.389l1.038-1.934 1.208.648-1.041 1.941c-.382-.254-.786-.473-1.205-.655zm-10.068 3.583l-2.054-1.472.799-1.115 1.942 1.393c-.264.366-.495.763-.687 1.194zm13.707 6.223l2.354.954-.514 1.271-2.425-.982c.21-.397.408-.812.585-1.243zm-13.108 1.155l-2.356 1.06-.562-1.251 2.34-1.052c.173.433.371.845.578 1.243zm-1.178-3.676h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-100">
                        Electricity
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                        - How much do you use annually?
                      </p>
                    </div>

                    <div class="mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userElectricity"
                        onChange={props.addData}
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder={props.electricity * 1000}
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userElectricityUnit"
                          onChange={props.addData}
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="kWh" default selected>
                            kWh
                          </option>

                          <option value="MWh">MWh</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {props.naturalGasCheck === true ? (
                <div class="w-full natural gas border-b mt-8 pb-8 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-100 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M19 6.734c0 4.164-3.75 6.98-3.75 10.266h-1.992c.001-2.079.996-3.826 1.968-5.513.913-1.585 1.774-3.083 1.774-4.753 0-3.108-2.518-4.734-5.004-4.734-2.482 0-4.996 1.626-4.996 4.734 0 1.67.861 3.168 1.774 4.753.972 1.687 1.966 3.434 1.967 5.513h-1.991c0-3.286-3.75-6.103-3.75-10.266 0-4.343 3.498-6.734 6.996-6.734 3.502 0 7.004 2.394 7.004 6.734zm-4 11.766c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm0 2c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5zm-1.701 3.159c-.19.216-.465.341-.752.341h-1.094c-.287 0-.562-.125-.752-.341l-1.451-1.659h5.5l-1.451 1.659zm2.013-18.767c-.489.287-.854.713-1.06 1.312-.556 1.609.373 2.928-.235 4.432-.355.875-1.104 1.33-1.889 1.41-.15 1.551.059 3.702.545 4.953h-1.113c-.581-2.322-.268-6.441 1.025-8.764-.787.603-1.564 2.254-1.79 3.567-2.738-1.642-.659-6.91 4.517-6.91z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-100">
                        Natural Gas
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                        - How much do you use annually?
                      </p>
                    </div>

                    <div class="mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userNaturalGas"
                        onChange={props.addData}
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder={(props.naturalGas / 0.1).toFixed(1)}
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userNaturalGasUnit"
                          onChange={props.addData}
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="Therm" default selected>
                            Therm
                          </option>
                          <option value="CCF">CCF</option>
                          <option value="MCF">MCF</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {props.propaneCheck === true ? (
                <div class="w-full propane border-b mt-8 pb-8 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-100 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M16 6c-3.652 5.397-6 8.774-6 12.056 0 3.283 2.684 5.944 6 5.944s6-2.661 6-5.944c0-3.282-2.348-6.659-6-12.056zm-.021 3.84c.351.543.771 1.49.771 2.49 0 2.931-3 3.411-3 1.627 0-1.224 1.492-3.033 2.229-4.117zm-7.975 8.16c.021-3.273 1.818-6.373 4.648-10.61-1.047-2.069-2.64-4.417-4.652-7.39-3.652 5.397-6 8.774-6 12.056 0 3.283 2.684 5.944 6 5.944h.004zm-2.254-10.042c0-1.225 1.492-3.033 2.229-4.118.351.543.771 1.49.771 2.49 0 2.931-3 3.411-3 1.628z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-100">
                        Propane
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                        - How much do you use annually?
                      </p>
                    </div>

                    <div class="mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userPropane"
                        onChange={props.addData}
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder={(props.propane * 10.93).toFixed(1)}
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userPropaneUnit"
                          onChange={props.addData}
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="Gal" default selected>
                            Gal
                          </option>

                          <option value="Qt">Qt</option>
                          <option value="L">L</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {props.heatingOilCheck === true ? (
                <div class="w-full heating oil mt-8 pb-2 space-y-6 ">
                  <div>
                    <div class="mt-12 flex items-center">
                      <svg
                        class="-mt-2 flex-shrink-0 text-emerald-100 h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M12 0c-4.87 7.197-8 11.699-8 16.075 0 4.378 3.579 7.925 8 7.925s8-3.547 8-7.925c0-4.376-3.13-8.878-8-16.075zm-.027 5.12c.467.725 1.027 1.987 1.027 3.32 0 3.908-4 4.548-4 2.17 0-1.633 1.988-4.044 2.973-5.49z" />
                      </svg>
                      <h1 class="text-2xl ml-2 font-bold text-emerald-100">
                        Heating Oil
                      </h1>
                      <p class="text-xs pl-2 pt-2 sm:text-sm text-white">
                        - How much do you use annually?
                      </p>
                    </div>

                    <div class="mt-6 relative rounded-md">
                      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                      <input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        name="userHeatingOil"
                        onChange={props.addData}
                        class="border border-emerald-900 rounded-md py-4 px-14 focus:ring-light-yellow block w-full text-gray-500 mt-1 block w-full pl-3 pr-10 text-lg focus:outline-none"
                        placeholder={(props.heatingOil * 7.22).toFixed(1)}
                      />
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <select
                          name="userHeatingOilUnit"
                          onChange={props.addData}
                          class="focus:ring-emerald-500 focus:border-emerald-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md focus:outline-none"
                        >
                          <option value="Gal" default selected>
                            Gal
                          </option>
                          <option value="Qt">Qt</option>
                          <option value="L">L</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div class="button-group pt-8 mt-8 md:flex justify-start ">
                <button
                  onClick={() => {
                    const newPosition = "Home2";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 border border-2 border-pacGreen flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-pacBlue bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 "
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    const newPosition = "Home4";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                >
                  Continue
                </button>
              </div>
            </div>
            <form class="checkout mt-5 md:pl-10 md:w-2/5 md:sticky md:top-5 ">
              <section class="mt-16 bg-gray-50 border border-emerald-500 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5">
                {props.data.uetGreen === "true" ? (
                  <span class="mb-5 flex justify-center w-1/2 mx-auto py-1 rounded-full text-xs font-medium bg-emerald-100 text-green-800">
                    {" "}
                    <svg
                      class="mr-2 h-5 text-green-800"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.787 7.531c-5.107 2.785-12.72 9.177-15.787 15.469h2.939c.819-2.021 2.522-4.536 3.851-5.902 8.386 3.747 17.21-2.775 17.21-11.343 0-1.535-.302-3.136-.92-4.755-2.347 3.119-5.647 1.052-10.851 1.625-7.657.844-11.162 6.797-8.764 11.54 3.506-3.415 9.523-6.38 12.322-6.634z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <h4 class=" text-sm font-medium text-green-800 mb-0">
                      Green Gas Customer
                    </h4>
                  </span>
                ) : (
                  <div></div>
                )}
                <div class="flex justify-center">
                  {" "}
                  <svg
                    class="h-7 md:h-8 text-emerald-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6 23h-3v-5.486c-1.827-.518-3-2.24-3-3.988 0-.585.129-1.172.407-1.716-.323-1.661.657-3.344 2.356-3.881l.018-.005c.773-1.102 2.057-1.826 3.535-1.898l5.666-5.026 12.018 10.661-1.33 1.493-10.688-9.482-3.274 2.905c.305.162.591.358.849.589l2.415-2.142 9.026 7.989v9.987h-2.998v-7h-5l-.008 7h-5.992v-6.061c.546-.125 1.034-.463 1.317-1.044 2.096.786 3.746-2.273 1.82-3.562 1.073-1.237.188-2.747-1.208-2.525-.673-2.384-4.178-2.384-4.851 0-1.31-.235-2.295 1.094-1.385 2.291-1.654 1.38-.162 4.084 1.872 3.473.214.74.794 1.2 1.435 1.362v6.066zm.451-10.678c1.493-1.023 3.242.768 2.303 2.226.002-1.473-.853-2.282-2.303-2.226m-2.119 1.191c-.668-1.002-.34-2.366.705-2.968.589-.338 1.33-.369 1.953-.07 1.06.507-2.743-.678-2.658 3.038"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <h2 class="text-xl mt-1 ml-2 md:text-2xl text-emerald-600">
                    Carbon Footprint
                  </h2>
                </div>

                <dl class="mt-3 space-y-4">
                  <p class="text-center mx-auto pb-4 px-4 text-base md:text-lg text-gray-500">
                    Your purchase will eliminate{" "}
                    <span class="text-emerald-600 font-bold ">
                      {" "}
                      {props.usage} TONS
                    </span>{" "}
                    of greenhouse gases Annually
                  </p>
                  <div class="border-t border-gray-200 pt-8 flex items-center justify-center">
                    <fieldset class="">
                      {props.data.priceType === "yearly" ? (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          onChange={props.addData}
                          class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10"
                        >
                          <div class="flex items-center">
                            <input
                              value="monthly"
                              name="priceType"
                              type="radio"
                              checked
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />

                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${(props.priceYear / 12).toFixed(2)}
                              <span class="text-base font-medium">/mo</span>
                            </p>
                          </div>

                          <div class="flex items-center">
                            <input
                              value="yearly"
                              name="priceType"
                              type="radio"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                            />
                            <p class="ml-3 block text-2xl font-bold text-emerald-900">
                              ${props.priceYear.toFixed(2)}
                              <span class="text-base font-medium">/yr</span>
                            </p>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>{" "}
                </dl>

                {props.usage === 0 ? (
                  <div class="mt-7">
                    <button
                      type="button"
                      class="w-full pointer-events-none opacity-50 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                ) : (
                  <div class="mt-7">
                    <button
                      onClick={() => {
                        const newPosition = "PreCheckout";
                        props.onChange(newPosition);
                      }}
                      type="button"
                      class="w-full py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
                    >
                      Offset Now
                    </button>
                  </div>
                )}
              </section>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home3;

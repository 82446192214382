import * as React from "react";

const Home2 = (props) => {
  return (
    <div class="home 2">
      {" "}
      <div class="hero mt-10 items-center pb-10 pt-4 md:pt-16 px-4 md:px-0">
        <div class="mx-auto max-w-7xl">
          <div class="flex justify-between items-center">
            <div class="md:w-1/2">
              <nav class="hidden md:block opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button
                        onClick={() => {
                          const newPosition = "Home1";
                          props.onChange(newPosition);
                        }}
                        class="ml-4 text-sm font-medium text-white hover:text-gray-200"
                      >
                        Residential
                      </button>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <button class="pointer-events-none ml-4 text-sm font-medium text-white">
                        House Info
                      </button>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Residential
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                The type of energy you use to power your home and the overall
                size of your home has an impact on your carbon footprint.
              </p>{" "}
              <div class="house size">
                <select
                  name="houseSize"
                  onChange={props.addData}
                  class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                >
                  <option value="0" default selected>
                    Estimated Home Size (In sq. ft)
                  </option>
                  <option disabled>------------------</option>
                  <option value="1 - 999">1 - 999</option>
                  <option value="1,000 - 1,499">1,000 - 1,499</option>
                  <option value="1,500 - 1,999">1,500 - 1,999</option>
                  <option value="2,000 - 2,499">2,000 - 2,499</option>
                  <option value="2,500 - 2,999">2,500 - 2,999</option>
                  <option value="3,000+">3,000+</option>
                  <option hidden>United States Minor Outlying Islands</option>
                </select>
              </div>
              <div class="members">
                <select
                  name="houseMembers"
                  onChange={props.addData}
                  class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                >
                  <option value="0" default selected>
                    # of household members?
                  </option>
                  <option disabled>------------------</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6+">6+</option>
                  <option hidden>United States Minor Outlying Islands</option>
                </select>
              </div>
              <div></div>
              <div class="button-group border-t pt-8 mt-8 md:flex justify-start ">
                <button
                  onClick={() => {
                    const newPosition = "Home1";
                    props.onChange(newPosition);
                  }}
                  class="w-full md:w-1/2 border border-2 border-pacGreen flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-pacBlue bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10 "
                >
                  Previous
                </button>

                {props.data.houseSize !== "0" &&
                props.data.houseMembers !== "0" ? (
                  <button
                    onClick={() => {
                      const newPosition = "Home3";
                      props.onChange(newPosition);
                    }}
                    class="w-full md:w-1/2 border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                  >
                    Continue
                  </button>
                ) : (
                  <button class="pointer-events-none w-full md:w-1/2 border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3 opacity-50">
                    Continue
                  </button>
                )}
              </div>
            </div>{" "}
            <div class="hidden md:block pl-10 w-1/2  relative"></div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home2;

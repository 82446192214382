import * as React from "react";

const Home1 = (props) => {
  return (
    <div class="home 1 px-4 md:px-0">
      {" "}
      <div class="hero mt-8 items-center pb-10 pt-4 md:pt-16">
        <div class="mx-auto max-w-7xl">
          <div class="flex justify-center items-center">
            <div class=" md:w-1/2">
              <nav class="hidden md:block opacity-100 flex" aria-p="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div>
                      <a href="/" class="text-white hover:text-gray-200">
                        <svg
                          class="flex-shrink-0 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                        </svg>
                        <span class="sr-only">Home</span>
                      </a>
                    </div>
                  </li>

                  <li>
                    <div class="flex items-center">
                      <svg
                        class="flex-shrink-0 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                      </svg>
                      <a class="ml-4 text-sm font-medium text-white">
                        Residential
                      </a>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                Residential
              </h1>
              <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                First choose your state for the home energy calculator below.
                This tool will calculate the carbon emissions associated with
                your home. We will first use your state to estimate usage, you
                can edit these values in later steps.
              </p>{" "}
              <div class="select state">
                <select
                  name="state"
                  onChange={props.addData}
                  class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                >
                  <option value="0" default selected>
                    Select your state of Residence
                  </option>
                  <option disabled>------------------</option>
                  <option value="1">Alabama</option>
                  <option value="2">Alaska</option>
                  <option value="3">Arizona</option>
                  <option value="4">Arkansas</option>
                  <option value="5">California</option>
                  <option value="6">Colorado</option>
                  <option value="7">Connecticut</option>
                  <option value="8">Delaware</option>
                  <option value="9">District Of Columbia</option>
                  <option value="10">Florida</option>
                  <option value="11">Georgia</option>
                  <option value="12">Hawaii</option>
                  <option value="13">Idaho</option>
                  <option value="14">Illinois</option>
                  <option value="15">Indiana</option>
                  <option value="16">Iowa</option>
                  <option value="17">Kansas</option>
                  <option value="18">Kentucky</option>
                  <option value="19">Louisiana</option>
                  <option value="20">Maine</option>
                  <option value="21">Maryland</option>
                  <option value="22">Massachusetts</option>
                  <option value="23">Michigan</option>
                  <option value="24">Minnesota</option>
                  <option value="25">Mississippi</option>
                  <option value="26">Missouri</option>
                  <option value="27">Montana</option>
                  <option value="28">Nebraska</option>
                  <option value="29">Nevada</option>
                  <option value="30">New Hampshire</option>
                  <option value="31">New Jersey</option>
                  <option value="32">New Mexico</option>
                  <option value="33">New York</option>
                  <option value="34">North Carolina</option>
                  <option value="35">North Dakota</option>
                  <option value="36">Ohio</option>
                  <option value="37">Oklahoma</option>
                  <option value="38">Oregon</option>
                  <option value="39">Pennsylvania</option>
                  <option value="40">Rhode Island</option>
                  <option value="41">South Carolina</option>
                  <option value="42">South Dakota</option>
                  <option value="43">Tennessee</option>
                  <option value="44">Texas</option>
                  <option value="45">Utah</option>
                  <option value="46">Vermont</option>
                  <option value="47">Virginia</option>
                  <option value="48">Washington</option>
                  <option value="49">West Virginia</option>
                  <option value="50">Wisconsin</option>
                  <option value="51">Wyoming</option>
                </select>
              </div>
              <div class="green">
                <div class="callective">
                  {props.data.state === "5" ? (
                    <div class="green customers">
                      <select
                        name="uetGreen"
                        onChange={props.addData}
                        class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      >
                        <option value="" default selected>
                          Are you a Callective Green Gas Customer?
                        </option>
                        <option disabled>------------------</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                        <option hidden>
                          United States Minor Outlying Islands
                        </option>
                      </select>
                      <div>
                        {props.data.uetGreen === "true" ? (
                          <div>
                            <h3 class="font-normal mt-6 text-base text-emerald-800">
                              Congratulations! You are already offsetting a
                              portion of your consumption as Callective is
                              purchasing carbon offsets for your current gas
                              usage!
                            </h3>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div class="kratos">
                  {props.data.state === "23" || props.state === "36" ? (
                    <div class="green customers">
                      <select
                        name="uetGreen"
                        onChange={props.addData}
                        class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      >
                        <option value="" default selected>
                          Are you a Kratos Green Gas Customer?
                        </option>
                        <option disabled>------------------</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                        <option hidden>
                          United States Minor Outlying Islands
                        </option>
                      </select>
                      <div>
                        {props.data.uetGreen === "true" ? (
                          <div>
                            <h3 class="font-normal mt-6 text-base text-emerald-800">
                              Congratulations! You are already offsetting a
                              portion of your consumption as Kratos Gas & Power
                              is purchasing carbon offsets for your current gas
                              usage!
                            </h3>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div class="uncle Frank">
                  {props.data.state === "28" || props.state === "51" ? (
                    <div class="green customers">
                      <select
                        name="uetGreen"
                        onChange={props.addData}
                        class=" cselect mt-8 block w-full pl-3 text-lg text-emerald-900 py-4 border border-emerald-900 focus:outline-none focus:ring-green-500 focus:border-green-500  rounded-md"
                      >
                        <option value="" default selected>
                          Are you an Uncle Frank Green Gas Customer?
                        </option>
                        <option disabled>------------------</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                        <option hidden>
                          United States Minor Outlying Islands
                        </option>
                      </select>
                      <div>
                        {props.data.uetGreen === "true" ? (
                          <div>
                            <h3 class="font-normal mt-6 text-base text-emerald-800">
                              Congratulations! You are already offsetting a
                              portion of your consumption as Uncle Frank Energy
                              is purchasing carbon offsets for your current gas
                              usage!
                            </h3>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
              <div class="button-group border-t pt-8 mt-8 md:flex justify-start ">
                <div class="md:w-1/2 rounded-md ">
                  <a
                    href="/"
                    class="border border-2 border-pacGreen flex items-center justify-center px-10 py-5  text-base font-medium rounded-md text-pacBlue bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10"
                  >
                    Home
                  </a>
                </div>

                {props.data.state !== "0" ? (
                  <button
                    onClick={() => {
                      const newPosition = "Home2";
                      props.onChange(newPosition);
                    }}
                    class="border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white  hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3"
                  >
                    Continue
                  </button>
                ) : (
                  <button class="pointer-events-none border border-2 border-white w-full md:w-1/2 mt-3 flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white hover:opacity-90 md:py-4 md:text-lg md:px-10 rounded-md  sm:mt-0 sm:ml-3 opacity-50">
                    Continue
                  </button>
                )}
              </div>
            </div>
            <div class="hidden md:block pl-10 w-1/2  relative"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home1;
